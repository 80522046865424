import React, {useEffect, useState} from 'react';
import StatsItem from "./StatsItem";
import {Box} from "@mui/material";
import RelancerClients from '../Espace-client/RelancerClients';

const StatsEspaceClientActivation = ({lots}) => {
    const [items, setItems] = useState([]);
    const [clientsDemandeAction, setClientsDemandeAction] = useState([]);

    useEffect(() => {
        const comptesInactif = lots.filter(l => l.client_activation && l.client_activation.statut_activation === "INACTIF");
        const clientsIdInactif = [...new Set(comptesInactif.map(lot => lot.transaction && lot.transaction.client_id))];

        const comptesDemandeActivation = lots.filter(l => l.client_activation && l.client_activation.statut_activation === "DEMANDE_ACTIVATION");
        const clientsIdDemandeActivation = [...new Set(comptesDemandeActivation.map(lot => lot.transaction && lot.transaction.client_id))];

        const comptesActivation = lots.filter(l => l.client_activation && l.client_activation.statut_activation === "ACTIVATION");
        const clientsIdActivation = [...new Set(comptesActivation.map(lot => lot.transaction && lot.transaction.client_id))];

        setItems([
            {
                label: "Espace non activé",
                value: clientsIdInactif.length,
                severity: "error"
            },
            {
                label: "Espace client activé",
                value: clientsIdDemandeActivation.length,
                severity: "warning"
            },
            {
                label: "Espace client utilisé",
                value: clientsIdActivation.length,
                severity: "success"
            }
        ]);


        setClientsDemandeAction(clientsIdDemandeActivation);
    }, [lots])

    return (
        <Box>
            <Box sx={{display: "grid", gridTemplateColumns: "3fr 50px", gap: "10px", gridAutoRows: "min-content"}}>
                {items && items.map((item, index) => (
                    <StatsItem item={item} key={index}/>
                ))}
            </Box>
            <Box sx={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
                <RelancerClients clientsId={clientsDemandeAction}/>
            </Box>
        </Box>
    );
};

export default StatsEspaceClientActivation;
